.accordionSummary {
  &&& {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    flex-flow: row-reverse;
    padding: 0 8px !important;
  }

  & > div:nth-child(2) {
    margin-right: 16px;
  }
}

.accordionSummaryContent {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.accordion {
  &&& {
    border-radius: 0;
    margin-bottom: 0;
    margin-top: 1px;
    background-color: #fafafa;
  }
}

.accordionDetails {
  &&& {
    padding: 0;
  }
}

.title {
  &&& {
    font-size: 14px !important;
  }
}

.nameWithIcon {
  display: flex;
  align-items: center;

  &&&& {
    svg {
      font-size: 24px;
      margin-right: 4px;
    }
  }
}

.subtitle {
  font-size: 12px !important;
}

.singleOnly {
  min-height: 0 !important;
  height: 0 !important;
  opacity: 0 !important;
}
