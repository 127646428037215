.wrapper {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
}

.deleteButton {
  flex-shrink: 0;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(29, 33, 50, 0.2);
  cursor: pointer;
  background-color: transparent;
  border-radius: 4px;
}

.input {
  flex-grow: 1;
}
