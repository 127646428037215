.wrapper {
  outline: 3px solid rgba(0, 0, 0, 0.4);
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.focused {
  opacity: 1;

  &:hover {
    opacity: 1;
  }
}

.topButton {
  &&& {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }
}

.bottomButton {
  &&& {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
}

.leftButton {
  &&& {
    position: absolute;
    left: 0;
    transform: translateY(-50%);
    top: 50%;
  }
}

.rightButton {
  &&& {
    position: absolute;
    right: 0;
    transform: translateY(-50%);
    top: 50%;
  }
}
