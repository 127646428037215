.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column;
}

.assessmentWrapper {
  display: flex;
  overflow-y: overlay;
  position: relative;
  flex-grow: 1;
}

.screenWrapper {
  height: 100%;
  flex-grow: 1;
  overflow: scroll;
}

.goToFlowButton {
  &&& {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
}

.reportDetailsContent {
  &&& {
    height: auto;
    padding-left: 0;
  }
}
