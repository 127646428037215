.wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  margin-top: 16px;
}

.image {
  display: block;
  max-width: 100%;
}

.imageWrapper {
  max-height: 280px;
  overflow-y: hidden;
  margin-bottom: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
  position: relative;
  cursor: pointer;

  &:hover {
    .videoIcon {
      &&& {
        opacity: 1;
      }
    }
  }
}

.videoIcon {
  &&& {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.8;
    width: 2em;
    height: 2em;
    fill: #248aff;
  }
}

.selectedTab {
  color: #000 !important;
  background-color: rgba(233, 242, 255, 1) !important;
}

.tab {
  padding: 12px 24px !important;
  max-width: 150px !important;
}
