.header {
  padding: 14px 12px 14px 16px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 20px;
  align-items: center;
}

.withDeleteButton {
  padding-right: 44px;
}

.clickable {
  cursor: pointer;

  &:hover {
    background-color: rgba(239, 242, 246, 1);
  }
}

.input {
  outline: none;
  border: none;
  background-color: transparent;
  flex-grow: 1;
  margin-right: 8px;
  font-size: 14px;
  line-height: 1.4;
  cursor: text;
  text-overflow: ellipsis;
}

.inputReadonly {
  pointer-events: none;
}

.assessments {
  display: flex;
  gap: 4px;
  align-items: center;
}

.deleteIconButton {
  border: 0;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  padding-top: 1px;
}

.customFragmentWrapper {
  display: flex;
  gap: 4px;
  align-items: center;
  color: rgba(29, 33, 50, 0.7);
}

.customFragmentText {
  font-size: 12px !important;
  line-height: 1.4 !important;
  color: currentColor !important;
  padding-top: 2px !important;
  cursor: default;
}
