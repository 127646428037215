.wrapper {
  width: 530px;
  border-left: 1px solid rgba(29, 33, 50, 0.2);
  flex-shrink: 0;
  overflow-y: overlay;
  display: flex;
  flex-flow: column;
  padding: 20px 0;
  box-sizing: border-box;
}

.wrapperWithPadding {
  padding: 20px;
}

.searchAndFilter {
  display: flex;
  gap: 16px;
}

.noRootFragmentsTitle {
  margin-top: 24px;
}

.totalTitle {
  margin-top: auto;
  margin-bottom: -8px;
}

.paddingContainer {
  padding: 0 20px;
}

.findForms {
  background-color: rgba(239, 242, 246, 1);
  padding: 24px;
}

.selectedTab {
  color: #000 !important;
  background-color: rgba(233, 242, 255, 1) !important;
}
