.wrapper {
  border-radius: 6px;
  background-color: rgba(239, 242, 246, 1);
  padding: 12px 16px;
  margin-bottom: 24px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.header {
  color: rgba(29, 33, 50, 0.7) !important;
  font-size: 14px !important;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  align-items: center;
  width: 100%;
}

.title {
  margin-bottom: 6px !important;
  font-size: 14px !important;
}

.moreLinkWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  color: rgba(70, 144, 255, 1);
  cursor: pointer;

  & svg path {
    fill: currentColor;
    fill-opacity: 1;
  }
}

.moreText {
  font-size: 14px !important;
  border-bottom: 1px dashed currentColor;
  line-height: 1.4 !important;
}

.applicabilityIcons {
  display: flex;
  gap: 4px;
}

.applicabilityIconWrapper {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
