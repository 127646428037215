.wrapper {
  flex-grow: 1;
  overflow: auto;
  position: relative;
  background-color: rgba(29, 33, 50, 0.04);
}

.textField {
  pointer-events: none;
}

.image {
  position: fixed;
  top: -999999px;
}

.canvasWrapper {
  height: 100%;
  margin-top: 100px;
}
