.wrapper {
  display: flex;
  flex-flow: column;
  width: 100vw;
  height: 100vh;
}

.flowWrapper {
  flex-grow: 1;
  width: 100vw;
}
