:root {
  --transition-border-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='darkblue' stroke-width='9' stroke-dasharray='7%2c 18' stroke-dashoffset='11' stroke-linecap='square'/%3e%3c/svg%3e");
}

.wrapper {
  position: relative;
  height: 150px;
  width: 200px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  cursor: pointer;

  &:hover {
    background-image: var(--transition-border-image);
  }
}

.empty-wrapper {
  opacity: 0.4;
  background-image: var(--transition-border-image);

  &:hover {
    opacity: 1;
  }
}

.addAction,
.addResult {
  border: 1px solid lightblue;
  padding: 12px;
  background-color: white;
  text-align: center;
  opacity: 0;
  font-size: 0.8rem;
  border-radius: 8px;
}

.addAction {
  color: blue;
}

.addActionWrapper {
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 50%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    .addAction {
      opacity: 1;
    }
  }
}

.addResultWrapper {
  position: absolute;
  bottom: -20px;
  right: 0;
  width: 50%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    .addResult {
      opacity: 1;
    }
  }
}
