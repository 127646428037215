.item {
  height: 28px;
  width: 28px;
  border-radius: 100%;
  border: 4px solid #fff;
  position: absolute;
  top: 19px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inactive {
  background-color: rgba(239, 242, 246, 1);
}

.wrapper {
  /* position: relative; */
}
