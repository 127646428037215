.assessmentWrapper {
  padding: 4px;
}

.assessment {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 12px;
  line-height: 1.4;
}

.medium {
  width: 24px;
  height: 24px;
}

.small {
  width: 20px;
  height: 20px;
}

.noPadding {
  padding: 0 !important;
}
