.wrapper {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.inner {
  border-radius: 6px;
  padding: 8px;
  border: 1px solid rgba(29, 33, 50, 0.2);
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
}

.disabled {
  pointer-events: none;
  cursor: default;
}

.largeButton {
  &&& {
    font-size: 14px;
  }
}

.buttons {
  display: flex;
  gap: 1px;
}

.label {
  &&& {
    font-size: 12px;
    padding-left: 0;
    color: rgba(51, 51, 51, 0.75);
  }
}

.labelError {
  &&& {
    color: #d32f2f;
  }
}

.tab {
  padding: 0 !important;
  min-height: unset !important;
}

.tabs {
  min-height: unset !important;
}

.flexContainer {
  gap: 4px !important;
}
