.wrapper {
  display: flex;
  overflow: auto;
}

.findForm {
  padding: 24px;
  border-right: 1px solid rgba(29, 33, 50, 0.2);
  width: 530px;
  flex-shrink: 0;
}

.title {
  font-size: 16px !important;
  margin-bottom: 24px !important;
}

.subtitle {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: rgba(29, 33, 50, 0.7) !important;
}

.text {
  font-size: 14px !important;
  margin-top: 4px !important;
  margin-bottom: 24px !important;
}

.bigText {
  font-size: 20px !important;
  margin-top: 4px !important;
  font-weight: bold;
  margin-bottom: 24px !important;
}

.heuristicTitle {
  display: flex;
  align-items: center;
  gap: 4px;
}

.heuristicButtonWrapper {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.heuristicButtonWrapperActive {
  background-color: rgba(233, 242, 255, 1);
  border-radius: 4px;
}

.heuristicInfo {
  border-right: 1px solid rgba(29, 33, 50, 0.2);
  border-left: unset;
  right: unset;
  left: 1040px;
}

.findTypeIconWrapper {
  height: 36px;
  width: 36px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.findTypeWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 24px;
}

.findTypeTitle {
  font-size: 14px !important;
  line-height: 1 !important;
  margin-bottom: 4px !important;
}

.findTypeSubtitle {
  font-size: 12px !important;
  color: rgba(29, 33, 50, 0.7) !important;
  line-height: 1 !important;
}
