.wrapper {
  padding-left: 0;
  padding-right: 32px;
  padding-top: 32px;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.columns {
  display: flex;
  gap: 32px;
  width: 100%;
}

.column {
  flex-grow: 1;
  min-width: 31%;
}

.title {
  &&& {
    font-size: 22px;
    font-weight: bold;
  }
}

.fragmentImage {
  height: 30vh;
  padding-bottom: 8px;
  padding-left: 32px;
}

.fragmentImageMobile {
  height: 100vh;
  padding-bottom: 0;
  min-width: 33%;
  padding-right: 32px;
}

.flexWrapper {
  display: flex;
  align-items: flex-start;
}

.heuristicsWrapper {
  margin-top: 20px;
}

.flexCenter {
  align-items: center;
}

.criticalityWrapper {
  margin-bottom: 8px;
}

.brainIcon {
  height: 24px;
  width: 24px;
  fill: currentColor;
}

.gptButton {
  &&& {
    flex-shrink: 0;
  }
}

.groupedHeuristicLabel {
  color: #089b28;
}

.findSelectWrapper {
  &&& button {
    max-width: 200px;
  }
}

.bad {
  color: #f24727;
}

.medium {
  color: #ff9901;
}

.good {
  color: #fac710;
}

.circle {
  height: 18px;
  width: 18px;
  border-radius: 100%;
  margin-right: 16px;
  background-color: currentColor;
  flex-shrink: 0;
}

.reportDetailsContent {
  &&& {
    height: auto;
    padding-left: 0;
    position: static;
  }
}

.radioWrapper {
  align-items: flex-start;
}

.radioLabel {
  padding-top: 8px;
}

.redStar {
  color: #f24727;
  margin-right: 4px;
}

.bottomButtons {
  padding: 16px;
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 100%;
  gap: 24px;
}

.innerContent {
  padding-left: 32px;
}

.completeButton {
  &&& {
    margin-left: auto;
  }
}

.customLabel {
  width: 100%;
}
