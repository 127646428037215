.wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}

.disabled {
  pointer-events: none;
  cursor: default;
}

.text {
  &&& {
    font-size: 14px !important;
    line-height: 1.4 !important;
  }
}

.inactiveInput {
  pointer-events: none;
}

.activeColor {
  color: #000 !important;
  -webkit-text-fill-color: #000 !important;
}

.activeHeuristic {
  background-color: rgba(233, 242, 255, 1);
  border-radius: 4px;
}

.activeLabel {
  color: rgba(51, 51, 51, 0.75) !important;
  -webkit-text-fill-color: rgba(51, 51, 51, 0.75) !important;
}

.textWrapper {
  padding: 4px 8px 4px 32px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  flex-grow: 1;
  min-height: 40px;
  box-sizing: border-box;

  &:hover {
    background-color: rgba(239, 242, 246, 1);

    .starIcon {
      opacity: 0;
    }

    .plusIcon {
      opacity: 1;
    }
  }
}

.detailsIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    background-color: rgba(239, 242, 246, 1);
  }
}

.icon {
  position: absolute;
  top: 7px;
  left: 10px;
}

.starIcon {
  opacity: 1;
}

.plusIcon {
  opacity: 0;
}

.closeIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
