.accordionSummary {
  padding: 0 8px !important;

  & > div:nth-child(2) {
    margin-right: 16px;
  }
}

.accordionSummaryContent {
  justify-content: space-between;
}

.navigation {
  width: 25vw;
  min-width: 520px;
  flex-shrink: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  overflow-y: overlay;
  padding: 16px 24px;
}

.navigation svg {
  &&& {
    font-size: 20px;
  }
}

.title {
  &&& {
    font-size: 14px !important;
  }
}

.totalAssessments {
  padding: 21px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  & > div:nth-child(2) {
    margin-left: 24px;
  }
}

.accordionDetails {
  &&& {
    padding: 0;
  }
}

.coloredSection {
  &&& {
    background-color: #fafafa;
  }
}

:global(.MuiAccordionSummary-content.Mui-expanded) {
  &&& {
    margin: 12px 0;
  }
}

.wrapper {
  display: flex;
  flex-flow: column;
  width: 100vw;
  height: 100vh;
}

.navigationAndDetailsWrapper {
  display: flex;
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  max-width: 100vw;
}

.tab {
  &&& {
    flex-grow: 1;
  }
}

.subtitle {
  font-size: 12px !important;
}

.titlesWrapper {
  max-width: 290px;
}
