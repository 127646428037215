.wrapper {
  position: relative;

  &:hover .input {
    opacity: 1;
  }

  &:hover .dragImageLabel,
  &:hover .dragImageLabelWrapper {
    opacity: 1;
  }
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.innerNoImage {
  background-color: rgba(239, 242, 246, 1);
  outline: 1px dashed rgba(29, 33, 50, 0.2);
}

.inner {
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  cursor: pointer;
  background-size: cover;
}

.input {
  background: transparent;
  border: 0;
  position: absolute;
  top: -20px;
  text-align: center;
  width: 100%;
  outline: none;
  padding-bottom: 6px;

  &:focus {
    opacity: 1;

    &::-webkit-input-placeholder {
      opacity: 0;
    }
  }
}

.input-empty {
  opacity: 0;

  &:focus {
    opacity: 1;
  }
}

.dragImageLabel {
  opacity: 1;
  font-size: 14px;
}

.dragImageLabelWrapper {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  opacity: 0.6;
}

.addColumn {
  border: 1px solid lightblue;
  padding: 12px;
  background-color: white;
  text-align: center;
  opacity: 0;
  font-size: 0.8rem;
  border-radius: 8px;
  position: relative;
  z-index: 999;
}

.addColumnHighlight {
  position: absolute;
  width: 46px;
  height: 2000px;
  background-color: rgba(51, 51, 181);
  opacity: 0.3;
  display: none;
}

.addColumnWrapper {
  position: absolute;
  bottom: -45px;
  right: -108px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    .addColumn {
      opacity: 1;
    }

    .addColumnHighlight {
      display: block;
    }
  }
}

.addPair {
  border: 1px solid lightblue;
  padding: 12px;
  background-color: white;
  text-align: center;
  opacity: 0;
  font-size: 0.8rem;
  border-radius: 8px;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-21px, -50%);
}

.addPairHighlight {
  position: absolute;
  height: 100%;
  background-color: rgba(228, 232, 238, 1);
  display: none;
  width: 100%;
  border-radius: 6px;
}

.addPairWrapper {
  position: absolute;
  bottom: 0;
  right: -48px;
  height: 100%;
  width: 46px;
  cursor: pointer;

  &:hover {
    .addPair {
      opacity: 1;
    }

    .addPairHighlight {
      display: block;
    }

    .deleteIcon,
    .addIcon {
      &&& {
        opacity: 1;
      }
    }
  }
}

.deleteIcon {
  &&& {
    position: absolute;
    bottom: 32px;
    opacity: 0;
    left: 3px;
  }
}

.addIcon {
  &&& {
    position: absolute;
    top: 32px;
    opacity: 0;
    left: 3px;
  }
}

.screenAssessmentsWrapper {
  position: relative;
}

.screenAssessment {
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border: 4px solid #fff;
  border-radius: 100%;
  top: -16px;
  position: absolute;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
}
