.wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(38, 38, 38);
}

.form {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  width: 400px;
}
