.wrapper {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  padding-left: 20px;
  overflow-y: overlay;
  position: absolute;
  width: 530px;
  right: 530px;
  background-color: #fff;
  border-left: 1px solid rgba(29, 33, 50, 0.2);
  padding: 24px !important;
  z-index: 1;
  max-height: calc(100vh - 75px);
}

.wrapperMobile {
  flex-flow: row;
}

.fragmentImage {
  height: 37%;
  padding-bottom: 8px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
}

.fragmentImageMobile {
  height: 100%;
  padding-bottom: 0;
  min-width: 33%;
  padding-right: 32px;
}

.heuristicAndDescriptionWrapper {
  height: 63%;
}

.heuristicAndDescriptionWrapperMobile {
  overflow: auto;
}

.heuristic {
  display: flex;
  flex-flow: column;
}

.onlyHeuristic {
  padding-left: 8px;
}

.mobileRecommendationsWrapper {
  display: flex;
  gap: 32px;
}

.heuristicMobile {
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 0;
  padding-right: 32px;
}

.heuristicHeader {
  width: 100%;
  position: sticky;
}

.heuristicContent {
  height: 100%;
  overflow-y: overlay;
  margin-bottom: 24px;
}

.heuristicContentMobile {
  height: auto;
}

.heuristicContentFullWidth {
  width: 100%;
  padding-bottom: 20px;
}

.heuristicContentHalfWidth {
  padding: 0;
  flex-grow: 1;
}

.heuristicHalf {
  width: 50%;
  padding: 12px;
  padding-left: 24px;

  &:first-child {
    padding-left: 0;
  }
}

.description {
  &&& {
    margin-bottom: 16px;
    font-size: 14px;
  }
}

.link {
  color: #2c9bf0;
  font-size: 14px;
}

.list {
  padding-left: 32px;
  margin-top: 8px;
}

.accordionSummary {
  border: 1px solid rgba(0, 0, 0, 0.2);
  flex-flow: row-reverse;

  & > div:nth-child(2) {
    margin-right: 16px;
  }
}

.accordion {
  &&& {
    border-radius: 0;
    border-bottom: 0;
    box-shadow: none;
    margin-bottom: 8px;
    margin-top: 1px;
    width: 100%;
  }
}

.materialsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.materialsHeaderMobile {
  justify-content: flex-start;
}

.materialsButton {
  &&& {
    text-transform: none;
  }
}

.materialsButtonMobile {
  &&& {
    margin-left: 16px;
  }
}

.recommendationHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.recommendationHeaderMobile {
  margin-bottom: 16px;
}

.recommendationIcon {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.good {
  background-color: rgba(214, 255, 188, 1);
}

.bad {
  background-color: rgba(255, 230, 226, 1);
  transform: scale(1, -1);
}

.closeButton {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background-color: rgba(239, 242, 246, 1);
  border-radius: 4px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  min-height: 31px;
  margin-bottom: 16px;
}

.tag {
  padding: 4px 8px;
  border: 1px solid rgba(29, 33, 50, 0.2);
  border-radius: 100px;
  background-color: #fff;
  color: rgba(29, 33, 50, 0.7);
  font-size: 12px;
}

.moreTagsButton {
  color: rgba(29, 33, 50, 0.7) !important;

  & span {
    margin-left: 4px !important;
  }
}

.material {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 8px;

  & svg {
    flex-shrink: 0;
  }
}

.materials {
  margin-top: 12px;
  margin-bottom: 32px;
}
