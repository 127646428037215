.button {
  &&& {
    flex-grow: 1;
    font-size: 12px;
    box-shadow: none !important;
    color: #000 !important;
  }
}

.buttonContained {
  border-radius: 4px !important;
  padding: 6px 8px !important;
}

.outlined {
  border-color: rgba(29, 33, 50, 0.2) !important;
}

.noGrow {
  &&& {
    flex-grow: 0;
  }
}

.disabled {
  pointer-events: none;
}

.disabledAndNotSelected {
  opacity: 0.4;
}
