.submitButton {
  &&& {
    align-self: flex-end;
  }
}

.form {
}

.multiple {
  margin-bottom: 12px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  border: 1px solid rgba(29, 33, 50, 0.2);
  padding: 8px 16px 16px 16px;
}

.searchAndFilter {
  display: flex;
  gap: 16px;
  padding-right: 0;
  margin-top: 0;
}

.heuristicWithButton {
  display: flex;
}

.heuristicButton {
  &&& {
    flex-shrink: 0;
  }
}

.criticality {
  margin-top: 24px;
}

.numberWithDeleteButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.trashButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  cursor: pointer;
  border: 0;
  background-color: #fff;
}

.required {
  color: rgba(228, 40, 11, 1);
  font-size: 1.3em;
  font-weight: bold;
}
