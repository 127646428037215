.react-flow__attribution {
  display: none !important;
}

.MuiButtonBase-root {
  text-transform: none !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
  z-index: 1;
}

.MuiTab-root {
  padding: 12px 24px !important;
}

/* .MuiTab-root.Mui-selected {
  color: #000 !important;
  background-color: rgba(233, 242, 255, 1) !important;
} */

.MuiTabs-root,
.MuiTabs-flexContainer {
  /* height: 100% !important; */
}

& .MuiButton-startIcon {
  margin-right: 4px !important;
}

* {
  box-sizing: border-box;
}

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3 !important;
}
