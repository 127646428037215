.reportHeuristic {
  width: auto;
  padding: 16px 16px 16px 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
}

.inProgress {
  background-color: rgb(105, 181, 236);
}

.button {
  &&& {
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.dottedIcon {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: 2px dashed #000;
  }
}

.subtitle {
  &&& {
    font-size: 12px;
    opacity: 0.6;
    line-height: 1;
  }
}

.dashedLine {
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
}

.dashedBottomLine {
  display: block;

  &::after {
    content: '';
    position: absolute;
    background: repeating-linear-gradient(
      0,
      #000 0px,
      #000 5px,
      transparent 5px,
      transparent 9px
    );
    width: 2px;
    height: 50px;
    top: calc(100% + 2px);
    left: calc(50%);
    transform: translateX(-50%);
  }
}

.dashedTopLine {
  display: block;

  &::before {
    content: '';
    position: absolute;
    background: repeating-linear-gradient(
      0,
      #000 0px,
      #000 5px,
      transparent 5px,
      transparent 9px
    );
    width: 2px;
    height: 50px;
    bottom: calc(100% + 2px);
    left: calc(50%);
    transform: translateX(-50%);
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -3px;
  margin-right: 14px;
}

.active {
  background-color: #c0e1fa;

  & .circle {
    opacity: 1;
  }
}

.circle {
  height: 18px;
  width: 18px;
  border-radius: 100%;
  margin-right: 16px;
  flex-shrink: 0;
  position: relative;
}

.good {
  background-color: #8fd14f;
}

.medium {
  background-color: #ff9901;
}

.bad {
  background-color: #f24727;
}

.low {
  background-color: #fac710;
}

.title {
  &&& {
    font-size: 14px !important;
  }
}

.icon {
  &&& {
    height: 22px;
    width: 22px;
    opacity: 0.9;
  }
}

.excludedIcon {
  &&&& {
    fill: red;
    position: absolute;
    left: 4px;
    bottom: 4px;
    font-size: 20px;
  }
}

.disabledIcon {
  &&&& {
    opacity: 0.4;
  }
}
