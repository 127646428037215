.wrapper {
  width: 100vw;
  background-color: #fff;
  border-bottom: 1px solid rgba(29, 33, 50, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 3;
}

.left {
  display: flex;
  align-items: center;
}

.right {
  text-align: right;
  display: flex;
  gap: 24px;
  align-items: center;
}

.email {
  opacity: 0.6;
  font-size: 12px !important;
}

.icon {
  margin-left: 12px;
  margin-right: 8px;
  width: 20px;
}

.leftButton {
  &&& {
    margin-left: 16px;
    margin-right: 8px;
  }
}

.button {
  flex-shrink: 0;
}

.menuIconWrapper {
  background-color: #eff2f6;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs {
  height: 100%;
}

.selectedTab {
  color: #000 !important;
  background-color: rgba(233, 242, 255, 1) !important;
}

.tab {
  padding: 12px 24px !important;
}

.profileIconWrapper {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: rgba(239, 242, 246, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}
