.items {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 0;
  opacity: 1;
  padding-bottom: 2px;
}

.item {
  display: flex;
  gap: 4px;
  align-items: center;
  color: rgba(29, 33, 50, 0.7);

  & svg {
    &&& {
      font-size: 14px;
    }
  }
}

.itemText {
  font-size: 12px;
}

.wrapper {
  padding: 0 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
}

.withoutBottomBorder {
  border-bottom: none;
}

.inputWrapper {
  display: flex;
}

.formControl {
  &&& {
    min-width: 300px;
    flex-shrink: 0;
  }
}

.grey {
  color: rgba(29, 33, 50, 0.7);
}

.scenarioTItle {
  margin-bottom: 4px;
}

.titleWithItemsWrapper {
  padding: 16px 40px 16px 0;
}
