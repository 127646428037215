.wrapper {
  padding: 6px 12px 6px 16px;
  background-color: rgba(239, 242, 246, 1);
  display: flex;
  justify-content: space-between;
  gap: 16px;
  border-radius: 4px;
  margin-bottom: 8px;
  align-items: center;
}

.withDeleteButton {
  padding-right: 44px;
}

.title {
  font-size: 14px !important;
  line-height: 1.4 !important;
  font-weight: 600 !important;
}

.icons {
  display: flex;
  gap: 4px;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 24px;
}
