.wrapper {
  overflow: auto;
  position: relative;
  height: 100%;
}

.image {
  position: fixed;
  top: -999999px;
}
