.wrapper {
  padding: 24px 16px;
  position: absolute;
  z-index: 1;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  top: 8px;
  left: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  gap: 16px;
  min-width: 120px;
  align-items: center;
}

.icon {
  &&& {
    font-size: 24px;
  }
}

.screenName {
  &&& {
    line-height: 1.4;
  }
}

.actionName {
  &&& {
    font-size: 12px;
    line-height: 1.4;
  }
}

.iconWrapper {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  background-color: rgba(239, 242, 246, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
}

.gray {
  color: rgba(29, 33, 50, 0.7);
}
