.popup {
}

.recommendationIcon {
  &&& {
    font-size: 1.5rem;
    flex-shrink: 0;
    margin-right: 12px;
  }
}

.good {
  &&& {
    fill: #0ca789;
  }
}

.bad {
  &&& {
    fill: #f24727;
  }
}

.descriptionWrapper {
  display: flex;
  padding: 16px 24px;
  align-items: center;
}
